<template>
  <section>
    <b-card
      no-body
      class="mb-0"
    >
      <div class="card-body">
        <div class="d-sm-flex justify-content-start">
          <button
            class="compact ui button text-height"
            @click="getNewDataCauHinhQuyTrinh"
          >
            <refresh-cw-icon
              size="12"
              class="custom-class line-top"
              :stroke-width="3"
            /> Tải lại
          </button>
          <button
            class="compact ui btn-primary button text-height"
            @click="addCommom"
          >
            <plus-icon
              size="12"
              class="custom-class line-top"
              :stroke-width="3"
            /> Thêm mới
          </button>
          <button
            class="compact ui btn-primary button text-height"
            :class="selectedNumber < 1 ? 'disabled' : ''"
            @click="deleteCommom"
          >
            <trash-2-icon
              size="12"
              class="custom-class line-top"
              :stroke-width="3"
            />
            Xoá
          </button>
        </div>
        <good-table
          ref="vgTable"
          class="mt-1"
          :columns="columns"
          :rows="rows"
          :total="total"
          :is-loading="isLoading"
          @column-filter="columnFilter"
          @selected-item="selectedItem"
          @page-change="pageChange"
        >
          <template
            slot="custom-filter"
            slot-scope="{ props }"
          >
            <span v-if="props.column.field == 'tenTTHC'">
              <treeselect
                id="linhVuc"
                ref="tenLinhVuc"
                v-model="payload.dataTTHCId"
                v-format-v-select
                :default-options="tthcCbx"
                placeholder="Chọn thủ tục hành chính"
                :limit="0"
                multiple
                class="vtreeselect-chooser"
                :limit-text="(count) => ` + ${count} lựa chọn`"
                no-options-text="Không có dữ liệu"
                no-results-text="Không có dữ liệu"
                loading-text="Đang tìm kiếm"
                :normalizer="normalizer"
                :match-keys="['label', 'label2']"
                :load-options="onQueryChange"
                :async="true"
                :clear-on-select="true"
                :before-clear-all="clearTreeSelect"
                @select="$refs.tenLinhVuc.resetSearchQuery(); nextTickTreeSelect()"
                @deselect="nextTickTreeSelect()"
              >
                <label
                  slot="option-label"
                  slot-scope="{ node, shouldShowCount, count, labelClassName }"
                  :class="labelClassName"
                  :title="node.label"
                >
                  {{ node.label }}
                </label>
              </treeselect>
            </span>
            <span v-else-if="props.column.field == 'tenDonVi'">
              <treeselect
                id="linhVuc"
                v-model="payload.dataDonViId"
                v-format-v-select
                :options="dvCbx"
                placeholder="Chọn đơn vị"
                :normalizer="normalizerDonVi"
                :match-keys="['label', 'name']"
                :limit="0"
                :multiple="true"
                :limit-text="(count) => ` + ${count} lựa chọn`"
                no-options-text="Không có dữ liệu"
                no-results-text="Không có dữ liệu"
                class="vtreeselect-chooser"
                :before-clear-all="clearTreeSelect"
                @select="nextTickTreeSelect()"
                @deselect="nextTickTreeSelect()"
              />
            </span>
          </template>
          <div
            slot="actions"
            slot-scope="{ props }"
          >
            <edit-icon
              v-b-tooltip.hover.v-secondary
              title="Cập nhật"
              size="16"
              class="custom-class cursor-pointer mr-1"
              @click="updateCommom(props.row)"
            />
            <trash-2-icon
              v-b-tooltip.hover.v-secondary
              title="Xóa"
              size="16"
              class="custom-class cursor-pointer"
              @click="deleteCommomTable(props.row)"
            />
          </div>
        </good-table>
      </div>
      <common-modal
        ref="commonModal"
        :title="title"
        :size="size"
        @handle-ok="handleOk"
        @handle-focus="handleFocusError"
      >
        <component
          :is="componentName === 'XoaForm' ? '' : componentName"
          ref="componentName"
          :data-form="dataForm"
        />
        <div
          v-if="isShow"
          slot="footer"
        >
          <b-button
            variant="primary"
            size="sm"
            class="mr-1"
            @click="$refs.commonModal.checkValidate()"
          >
            Lưu
          </b-button>
          <b-button
            variant="danger"
            size="sm"
            @click="openModal"
          >
            Đóng
          </b-button>
        </div>
      </common-modal>
      <common-modal
        ref="commonnModal"
        title="Xác nhận"
        size="sm"
      >
        Bạn có xác nhận đóng quá trình nhập liệu này không?
        <div
          v-if="isShowFooter"
          slot="footer"
        >
          <b-button
            variant="primary"
            size="sm"
            class="mr-1"
            @click="$refs.commonModal.hideModal(), $refs.commonnModal.hideModal()"
          >
            Đồng ý
          </b-button>
          <b-button
            variant="danger"
            size="sm"
            @click="$refs.commonnModal.hideModal()"
          >
            Đóng
          </b-button>
        </div>
      </common-modal>
    </b-card>
  </section>
</template>

<script>
import END_POINTS from '@/api/endpoints'
import {
  BCard,
  BFormInput,
  BButton,
  BFormGroup,
  BFormCheckbox,
  VBTooltip,
} from 'bootstrap-vue'
import { ROUTE_NAME } from '@/modules/quan-ly-quy-trinh/constants/constants'
import {
  RefreshCwIcon,
  PlusIcon,
  XIcon,
  EditIcon,
  Trash2Icon,
} from 'vue-feather-icons'
import GoodTable from '@/components/GoodTable.vue'
import CauHinhQuyTrinhForm from '@/modules/quan-ly-quy-trinh/components/form/CauHinhQuyTrinhForm.vue'
import addCssSemantic from '@/mixins/mixins'
import CommonModal from '@/modules/mot-cua/components/modal/CommonModal.vue'
import { compareObjects, removeDiacritical } from '@/utils/index'
import _cloneDeep from 'lodash/cloneDeep'
import _filter from 'lodash/filter'
import _isEmpty from 'lodash/isEmpty'
import _debounce from 'lodash/debounce'

export default {
  name: 'DanhSachHoSo',
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    RefreshCwIcon,
    BButton,
    PlusIcon,
    BFormInput,
    BFormGroup,
    BFormCheckbox,
    GoodTable,
    XIcon,
    BCard,
    CauHinhQuyTrinhForm,
    CommonModal,
    EditIcon,
    Trash2Icon,
  },
  mixins: [addCssSemantic],
  data() {
    return {
      ROUTE_NAME,
      title: 'Thêm mới cầu hình quy trình',
      columns: [
        {
          label: 'STT',
          thClass: 'text-center custom-style',
          tdClass: 'text-center',
          width: '50px',
          field: 'STT',
          sortable: false,
        },
        {
          label: 'Tên thủ tục',
          field: 'tenTTHC',
          width: '350px',
          thClass: 'text-center',
        },
        {
          label: 'Tên đơn vị',
          field: 'tenDonVi',
          width: '250px',
          thClass: 'text-center',
        },
        {
          label: 'Quy trình',
          field: 'workflowSchemeCode',
          thClass: 'text-center',
          width: 'auto',
          filterOptions: {
            enabled: true,
            placeholder: 'Tìm kiếm',
            filterValue: null,
          },
        },
        {
          label: 'Tên mẫu hồ sơ',
          field: 'tenMauHoSo',
          width: '300px',
          thClass: 'text-center',
        },
        {
          label: 'Thao tác',
          field: 'actions',
          thClass: 'text-center',
          tdClass: 'text-center vertical-align-middle',
          width: '100px',
        },
      ],
      rows: [],
      selectedItems: [],
      bcCbx: [],
      dvCbx: [],
      componentName: null,
      total: 0,
      selectedNumber: 0,
      payload: {
        pageSize: 10,
        pageNumber: 1,
        value: null,
        name: null,
        type: 'BUSSINESS_FLOW',
        dataTTHCId: [],
        dataDonViId: [],
        dataMauHoSoId: [],
        dataWorkflowAttributeId: [],
        schemeCode: null,
        fields: null,
        sort: 'Id',
        filter: null,
        fullTextSearch: null,
        id: null,
        listId: [],
      },
      isShow: false,
      isShowFooter: false,
      dataForm: {
        ds: [
          {
            workflowSchemeAttributeId: null,
            mauHoSoId: null,
            donViId: null,
            tongSoNgayXuLy: 0,
          },
        ],
        thuTucHanhChinhId: null,
      },
      beginObject: {},
      isLoading: false,
      size: null,
      lxlCbx: [],
      tthcCbx: [],
    }
  },
  created() {
    this.getDataCauHinhQuyTrinh()
    this.getDataQuyTrinh()
    this.getDataDonVi()
    this.getDataThuTuc()
  },
  methods: {
    openModal() {
      if (compareObjects(this.beginObject, this.dataForm)) {
        this.$refs.commonModal.hideModal()
        this.$refs.commonnModal.hideModal()
      } else {
        this.$refs.commonnModal.showModal()
        this.isShowFooter = true
      }
    },
    addCommom() {
      this.isShow = true
      this.title = 'Thêm mới cấu hình quy trình'
      this.componentName = 'CauHinhQuyTrinhForm'
      this.size = 'xl'
      this.dataForm = {
        ds: [
          {
            workflowSchemeAttributeId: null,
            mauHoSoId: null,
            donViId: null,
            tongSoNgayXuLy: 0,
          },
        ],
        thuTucHanhChinhId: null,
      }
      this.$refs.commonModal.showModal()
    },
    onQueryChange({ action, searchQuery, callback }) {
      if (action === 'ASYNC_SEARCH') {
        this.handleSearchQueryChange(searchQuery, callback)
      }
    },
    // eslint-disable-next-line func-names
    handleSearchQueryChange: _debounce(async function (query, callback) {
      const response = this.tthcCbx.filter(item => removeDiacritical(item.name).indexOf(removeDiacritical(query)) >= 0)
      callback(null, response)
    }, 100),
    getDataThuTuc() {
      this.$axios.get(END_POINTS.THU_TUC_HANH_CHINH.COMBOBOX).then(res => {
        if (res.data?.succeeded) {
          this.tthcCbx = res.data.data
        }
      })
    },
    getDataDonVi() {
      this.$axios.get(END_POINTS.CHUNG.DON_VI).then(res => {
        if (res.data?.succeeded) {
          const formatDonVi = _cloneDeep(res.data.data)
          this.dvCbx = this.listToTree(formatDonVi)
        }
      })
    },
    getDataQuyTrinh() {
      const payload = {
        page: 1,
        size: 9999999,
        code: null,
        donviId: null,
        fields: null,
        fullTextSearch: null,
        id: null,
        listId: null,
        sort: '+Id',
      }
      this.$axios.post(END_POINTS.WORKFLOW_SCHEME_ATTRIBUTE.DS, payload, false).then(res => {
        if (res.data?.code === 200) {
          this.lxlCbx = res.data.data.content
        }
      })
    },
    selectedItem(data) {
      this.selectedItems = data
      this.selectedNumber = data.length
    },
    getDataCauHinhQuyTrinh() {
      this.isLoading = false
      try {
        this.$axios.post(END_POINTS.WORKFLOW_GLOBAL_PARAMETERS.DS, this.payload, false).then(res => {
          if (res.data?.code === 200) {
            this.rows = res.data.data.content
            this.total = res.data.data.totalElements
          }
        })
      } finally {
        setTimeout(() => {
          this.isLoading = true
        }, 2000)
      }
    },
    resetFilter() {
      this.payload = {
        pageSize: 10,
        pageNumber: 1,
        value: null,
        name: null,
        type: 'BUSSINESS_FLOW',
        dataTTHCId: [],
        dataDonViId: [],
        dataMauHoSoId: [],
        dataWorkflowAttributeId: [],
        schemeCode: null,
        fields: null,
        sort: 'Id',
        fullTextSearch: null,
        id: null,
        listId: [],
      }
    },
    clearTreeSelect() {
      setTimeout(() => {
        this.nextTickTreeSelect()
      }, 200)
      return true
    },
    nextTickTreeSelect() {
      this.$nextTick(() => {
        this.getDataCauHinhQuyTrinh()
      })
    },
    getNewDataCauHinhQuyTrinh() {
      this.$refs.vgTable.$refs.vbTables.reset()
      this.resetFilter()
      this.getDataCauHinhQuyTrinh()
    },
    handleOk() {
      if (this.componentName === 'XoaForm') {
        const payload = {
          listId: this.selectedItems.map(item => item.id),
        }
        this.$axios.delete(END_POINTS.WORKFLOW_GLOBAL_PARAMETERS.XOA, payload, false).then(res => {
          if (res.data?.code === 200) {
            if (this.selectedItems.length >= this.rows.length && this.payload.pageNumber > 1) {
              this.payload.pageNumber -= 1
              this.$refs.vgTable.resetCurrentPage(this.payload.pageNumber)
            }
            this.$toast.success(res.data.message)
            this.getDataCauHinhQuyTrinh()
            this.$refs.commonModal.hideModal()
            if (res.data.data) {
              res.data.data.forEach(item => {
                if (!item.succeeded) {
                  this.$toast.error(item.message)
                }
              })
            }
          } else {
            this.$toast.error(res.data.message)
          }
          this.$refs.commonModal.hideModal()
        })
      } else if (this.componentName === 'CauHinhQuyTrinhForm') {
        if (this.dataForm?.id) {
          try {
            const dataForm = this.dataForm.ds.map(item => {
              const wf = this.lxlCbx.find(ele => ele.id === item.workflowSchemeAttributeId)
              return {
                id: item.id,
                type: 'BUSSINESS_FLOW',
                name: wf.description,
                value: JSON.stringify({
                  name: wf.description,
                  scheme: wf.code,
                }),
                cauHinhQtId: item.cauHinhQtId,
                workflowSchemeAttributeId: item.workflowSchemeAttributeId,
                mauHoSoId: item.mauHoSoId,
                donViId: item.donViId,
                thuTucHanhChinhId: this.dataForm.thuTucHanhChinhId,
                tongSoNgayXuLy: +item.tongSoNgayXuLy,
              }
            })
            this.$axios.put(END_POINTS.WORKFLOW_GLOBAL_PARAMETERS.SUA, dataForm, false).then(res => {
              if (res.data?.succeeded) {
                this.getDataCauHinhQuyTrinh()
                this.$refs.commonModal.hideModal()
                this.$toast.success(res.data.message)
                if (res.data.data) {
                  res.data.data.forEach(item => {
                    if (!item.succeeded) {
                      this.$toast.error(item.message)
                    }
                  })
                }
              } else {
                this.$toast.error(res.data.message)
              }
            })
          } catch (error) {
            this.$refs.componentName.errorTrungMa = 'Mã luồng xử lý tồn tại!'
          }
        } else {
          try {
            const dataForm = this.dataForm.ds.map(item => {
              const wf = this.lxlCbx.find(ele => ele.id === item.workflowSchemeAttributeId)
              return {
                type: 'BUSSINESS_FLOW',
                name: wf.description,
                value: JSON.stringify({
                  name: wf.description,
                  scheme: wf.code,
                }),
                workflowSchemeAttributeId: item.workflowSchemeAttributeId,
                mauHoSoId: item.mauHoSoId,
                donViId: item.donViId,
                thuTucHanhChinhId: this.dataForm.thuTucHanhChinhId,
                tongSoNgayXuLy: +item.tongSoNgayXuLy,
              }
            })
            this.$axios.post(END_POINTS.WORKFLOW_GLOBAL_PARAMETERS.THEM, dataForm, false).then(res => {
              if (res.data?.succeeded) {
                this.getDataCauHinhQuyTrinh()
                this.$refs.commonModal.hideModal()
                this.$toast.success(res.data.message)
                if (res.data.data) {
                  res.data.data.forEach(item => {
                    if (!item.succeeded) {
                      this.$toast.error(item.message)
                    }
                  })
                }
              } else {
                this.$toast.error(res.data.message)
              }
            })
          } catch (error) {
            this.$refs.componentName.errorTrungMa = 'Mã luồng xử lý tồn tại!'
          }
        }
      }
    },
    updateCommom(data) {
      this.isShow = true
      this.title = 'Cập nhật cấu hình quy trình'
      this.componentName = 'CauHinhQuyTrinhForm'
      this.size = 'xl'
      const payload = {
        pageNumber: 1,
        pageSize: this.total,
        value: null,
        name: null,
        type: 'BUSSINESS_FLOW',
        dataTTHCId: [data.tthcId],
        dataDonViId: [],
        dataMauHoSoId: [],
        schemeCode: null,
        fields: null,
        sort: 'Id',
        filter: null,
        fullTextSearch: null,
        id: null,
        listId: [],
      }
      this.$axios.post(END_POINTS.WORKFLOW_GLOBAL_PARAMETERS.DS, payload, false).then(res => {
        if (res.data?.code === 200) {
          this.dataForm = {
            id: data.tthcId,
            ds: res.data.data.content.map(item => ({
              id: item.id,
              cauHinhQtId: item.cauHinhQtId,
              workflowSchemeAttributeId: item.workflowSchemeAttributeId,
              mauHoSoId: item.mauHoSoId,
              donViId: item.donViId,
              tongSoNgayXuLy: item.tongSoNgayXuLy,
            })),
            thuTucHanhChinhId: data.tthcId,
          }
          this.$nextTick(() => {
            this.beginObject = _cloneDeep(this.dataForm)
            this.$refs.commonModal.showModal()
          })
        }
      })
    },
    deleteCommomTable(data) {
      this.isShow = false
      this.title = 'Xác nhận'
      this.dataForm = 'Bạn có xác nhận xóa hành động này không?'
      this.componentName = 'XoaForm'
      this.size = 'md'
      this.selectedItems = [data]
      this.$refs.commonModal.showModal()
    },
    deleteCommom() {
      this.isShow = false
      this.title = 'Xác nhận'
      this.dataForm = 'Bạn có xác nhận xóa hành động này không?'
      this.componentName = 'XoaForm'
      this.size = 'md'
      this.$refs.commonModal.showModal()
    },
    pageChange() {
      const data = this.$refs.vgTable.getCurrentPage()
      this.payload = {
        ...this.payload,
        pageNumber: data.pageNumber,
        pageSize: data.pageSize,
      }
      this.$axios.post(END_POINTS.WORKFLOW_GLOBAL_PARAMETERS.DS, this.payload, false).then(res => {
        if (res.data?.code === 200) {
          this.rows = res.data.data.content
          this.total = res.data.data.totalElements
        }
      })
    },
    columnFilter(data) {
      this.payload = {
        value: '',
        name: '',
        type: '',
        dataTTHCId: this.payload.dataTTHCId,
        dataDonViId: this.payload.dataDonViId,
        dataMauHoSoId: [],
        schemeCode: data.workflowSchemeCode ? data.workflowSchemeCode.replace(/\s+/g, ' ').trim() : '',
        dataWorkflowAttributeId: [],
        fields: '',
        sort: 'Id',
        fullTextSearch: '',
        id: '',
        listId: [],
        pageNumber: 1,
        pageSize: this.payload.pageSize,
      }
      this.$axios.post(END_POINTS.WORKFLOW_GLOBAL_PARAMETERS.DS, this.payload, false).then(res => {
        if (res.data?.code === 200) {
          this.rows = res.data.data.content
          this.total = res.data.data.totalElements
          this.$refs.vgTable.resetCurrentPage(1)
        }
      })
    },
    normalizer(node) {
      return {
        id: node.id,
        label: node.name,
        label2: removeDiacritical(node.name),
      }
    },
    normalizerDonVi(node) {
      if (node.children) {
        return {
          id: node.donViId,
          label: node.tenDonVi,
          name: removeDiacritical(node.tenDonVi),
          children: node.children,
        }
      }
      return {
        id: node.donViId,
        label: node.tenDonVi,
        name: removeDiacritical(node.tenDonVi),
      }
    },
    listToTree(array, parent, tree) {
      // eslint-disable-next-line no-param-reassign
      tree = typeof tree !== 'undefined' ? tree : []
      // eslint-disable-next-line no-param-reassign
      parent = typeof parent !== 'undefined' ? parent : { donViId: '00000000-0000-0000-0000-000000000000' }
      const children = _filter(array, child => child.iD_DonVi_Cha === parent.donViId)
      if (!_isEmpty(children)) {
        if (parent.donViId === '00000000-0000-0000-0000-000000000000') {
          // eslint-disable-next-line no-param-reassign
          tree = children
        } else {
          // eslint-disable-next-line no-param-reassign
          parent.children = children
        }
        children.forEach(child => {
          this.listToTree(array, child)
        })
      }
      return tree
    },
    columnFilterTable() {
      this.$nextTick(() => {
        this.$refs.vgTable.onColumnFilter()
      })
    },
    onFilterSelect(options, search) {
      return options.filter(item => removeDiacritical(item.name).indexOf(removeDiacritical(search.trim())) >= 0)
    },
    handleFocusError(first) {
      if (first) {
        const field = first[0]
        if (['thuTucHanhChinh'].includes(field)) {
          this.$refs.componentName.$refs[field].$el.querySelector('input').focus()
        }
        // } else {
        //   this.$refs.componentName.$refs[field].focus()
        // }
      }
    },
  },

}
</script>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  .card .card-header {
    border-bottom: 1.5px solid #EEEEEE !important;
    display: inline-block;
    padding: 0.5rem 1.5rem !important;
  }
  .cs-table {
    min-height: 150px;
    .actions-col-width {
      max-width: 50px;
    }
  }
  .font-weight-bold-700 {
    font-weight: 700;
    color: #333333;
  }

</style>
