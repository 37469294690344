var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('b-card',{staticClass:"mb-0",attrs:{"no-body":""}},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"d-sm-flex justify-content-start"},[_c('button',{staticClass:"compact ui button text-height",on:{"click":_vm.getNewDataCauHinhQuyTrinh}},[_c('refresh-cw-icon',{staticClass:"custom-class line-top",attrs:{"size":"12","stroke-width":3}}),_vm._v(" Tải lại ")],1),_c('button',{staticClass:"compact ui btn-primary button text-height",on:{"click":_vm.addCommom}},[_c('plus-icon',{staticClass:"custom-class line-top",attrs:{"size":"12","stroke-width":3}}),_vm._v(" Thêm mới ")],1),_c('button',{staticClass:"compact ui btn-primary button text-height",class:_vm.selectedNumber < 1 ? 'disabled' : '',on:{"click":_vm.deleteCommom}},[_c('trash-2-icon',{staticClass:"custom-class line-top",attrs:{"size":"12","stroke-width":3}}),_vm._v(" Xoá ")],1)]),_c('good-table',{ref:"vgTable",staticClass:"mt-1",attrs:{"columns":_vm.columns,"rows":_vm.rows,"total":_vm.total,"is-loading":_vm.isLoading},on:{"column-filter":_vm.columnFilter,"selected-item":_vm.selectedItem,"page-change":_vm.pageChange},scopedSlots:_vm._u([{key:"custom-filter",fn:function(ref){
var props = ref.props;
return [(props.column.field == 'tenTTHC')?_c('span',[_c('treeselect',{directives:[{name:"format-v-select",rawName:"v-format-v-select"}],ref:"tenLinhVuc",staticClass:"vtreeselect-chooser",attrs:{"id":"linhVuc","default-options":_vm.tthcCbx,"placeholder":"Chọn thủ tục hành chính","limit":0,"multiple":"","limit-text":function (count) { return (" + " + count + " lựa chọn"); },"no-options-text":"Không có dữ liệu","no-results-text":"Không có dữ liệu","loading-text":"Đang tìm kiếm","normalizer":_vm.normalizer,"match-keys":['label', 'label2'],"load-options":_vm.onQueryChange,"async":true,"clear-on-select":true,"before-clear-all":_vm.clearTreeSelect},on:{"select":function($event){_vm.$refs.tenLinhVuc.resetSearchQuery(); _vm.nextTickTreeSelect()},"deselect":function($event){return _vm.nextTickTreeSelect()}},scopedSlots:_vm._u([{key:"option-label",fn:function(ref){
var node = ref.node;
var shouldShowCount = ref.shouldShowCount;
var count = ref.count;
var labelClassName = ref.labelClassName;
return _c('label',{class:labelClassName,attrs:{"title":node.label}},[_vm._v(" "+_vm._s(node.label)+" ")])}}],null,true),model:{value:(_vm.payload.dataTTHCId),callback:function ($$v) {_vm.$set(_vm.payload, "dataTTHCId", $$v)},expression:"payload.dataTTHCId"}})],1):(props.column.field == 'tenDonVi')?_c('span',[_c('treeselect',{directives:[{name:"format-v-select",rawName:"v-format-v-select"}],staticClass:"vtreeselect-chooser",attrs:{"id":"linhVuc","options":_vm.dvCbx,"placeholder":"Chọn đơn vị","normalizer":_vm.normalizerDonVi,"match-keys":['label', 'name'],"limit":0,"multiple":true,"limit-text":function (count) { return (" + " + count + " lựa chọn"); },"no-options-text":"Không có dữ liệu","no-results-text":"Không có dữ liệu","before-clear-all":_vm.clearTreeSelect},on:{"select":function($event){return _vm.nextTickTreeSelect()},"deselect":function($event){return _vm.nextTickTreeSelect()}},model:{value:(_vm.payload.dataDonViId),callback:function ($$v) {_vm.$set(_vm.payload, "dataDonViId", $$v)},expression:"payload.dataDonViId"}})],1):_vm._e()]}},{key:"actions",fn:function(ref){
var props = ref.props;
return _c('div',{},[_c('edit-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-secondary",modifiers:{"hover":true,"v-secondary":true}}],staticClass:"custom-class cursor-pointer mr-1",attrs:{"title":"Cập nhật","size":"16"},on:{"click":function($event){return _vm.updateCommom(props.row)}}}),_c('trash-2-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-secondary",modifiers:{"hover":true,"v-secondary":true}}],staticClass:"custom-class cursor-pointer",attrs:{"title":"Xóa","size":"16"},on:{"click":function($event){return _vm.deleteCommomTable(props.row)}}})],1)}}])})],1),_c('common-modal',{ref:"commonModal",attrs:{"title":_vm.title,"size":_vm.size},on:{"handle-ok":_vm.handleOk,"handle-focus":_vm.handleFocusError}},[_c(_vm.componentName === 'XoaForm' ? '' : _vm.componentName,{ref:"componentName",tag:"component",attrs:{"data-form":_vm.dataForm}}),(_vm.isShow)?_c('div',{attrs:{"slot":"footer"},slot:"footer"},[_c('b-button',{staticClass:"mr-1",attrs:{"variant":"primary","size":"sm"},on:{"click":function($event){return _vm.$refs.commonModal.checkValidate()}}},[_vm._v(" Lưu ")]),_c('b-button',{attrs:{"variant":"danger","size":"sm"},on:{"click":_vm.openModal}},[_vm._v(" Đóng ")])],1):_vm._e()],1),_c('common-modal',{ref:"commonnModal",attrs:{"title":"Xác nhận","size":"sm"}},[_vm._v(" Bạn có xác nhận đóng quá trình nhập liệu này không? "),(_vm.isShowFooter)?_c('div',{attrs:{"slot":"footer"},slot:"footer"},[_c('b-button',{staticClass:"mr-1",attrs:{"variant":"primary","size":"sm"},on:{"click":function($event){_vm.$refs.commonModal.hideModal(), _vm.$refs.commonnModal.hideModal()}}},[_vm._v(" Đồng ý ")]),_c('b-button',{attrs:{"variant":"danger","size":"sm"},on:{"click":function($event){return _vm.$refs.commonnModal.hideModal()}}},[_vm._v(" Đóng ")])],1):_vm._e()])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }