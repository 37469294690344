<template>
  <cau-hinh-quy-trinh />
</template>

<script>
import CauHinhQuyTrinh from '@/modules/quan-ly-quy-trinh/components/pages/CauHinhQuyTrinh.vue'

export default {
  components: {
    CauHinhQuyTrinh,
  },
}
</script>

<style scoped></style>
